<template>
  <Navbar />
  <div class="row">
    <div class="card col-12 col-md-8 mx-auto">
      <div class="card-body">
        <div class="jumbotron">
          <div v-if="success === false">
            <div class="mx-auto">
              <div class="row">
                <div class="aboard-payment">
                  <img
                    src="@/assets/img/electronic_payment1.jpeg"
                    class="img-fluid"
                    alt=""
                  />
                  <br />

                  <div class="aboard-payment-text">
                    <b>
                      <span class="aboard-payment-title">
                        <span
                          class="fas fa-exclamation-triangle text-danger"
                        ></span>
                        ECHEC DE PAPAIEMENT
                      </span></b
                    >
                    <p class="board-payment-body">
                      <i>IL SEMBLE QUE VOTRE PAIEMENT N'A PAS ABOUTI...</i>
                      <br />
                      <i>VEUILLEZ RESSAYER.</i>
                    </p>
                  </div>
                  <br />
                  <div class="form-iline">
                    <router-link
                      :to="{
                        name: 'InitPayment',
                        params: { idCourse: course.id },
                      }"
                      class="btn btn-warning"
                      >Reesseyer</router-link
                    >
                    <router-link
                      class="btn btn-info ml-2"
                      :to="{ name: 'Courses' }"
                      >Liste de cours</router-link
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="success === true">
            <h2 class="text-success text-center">Payement reussi!</h2>
            <br />

            <div class="row">
              <div class="col-12 col-md-6">
                <img
                  class="img-fluid"
                  src="@/assets/img/success_payment.jpeg"
                  alt=""
                />
              </div>
              <div class="col-12 col-md-6">
                <img
                  class="img-fluid"
                  src="@/assets/img/payment_success_man.jpeg"
                  alt=""
                />
              </div>
            </div>
            <table class="table table-bordered">
              <tr>
                <th>Course</th>
                <td>{{ course.title }}</td>
              </tr>
            </table>

            <p>
              <router-link
                :to="{ name: 'ShowCourse', params: { idCourse: course.id } }"
                ><span
                  class="text-primary"
                  title="Cliquez ici pour debuter la formation"
                  ><u>Commencer la formation</u></span
                ></router-link
              >
            </p>
          </div>
          <div v-if="success=== null">
            <div class="text-center"><img  class="center img-fluid" src="@/assets/img/loader.gif" alt=""></div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <DashboardFooter/>
</template>
<script>
import Navbar from "@/components/layouts/Navbar.vue";
import DashboardFooter from '../../components/layouts/DashboardFooter.vue';
export default {
  data() {
    return {
      success: null,
      loading: true,
      course: {
        title: "",
        id: 0,
      },
    };
  },
  components: { 
    Navbar,
  DashboardFooter,
   },
  methods: {
    async verify() {
      await this.$http
        .post("/payment/check-payment-status/", {
          token: this.$route.params.token,
        })
        .then((res) => {
          this.loading = false;
          this.success = true;
          this.course = res.data;
          console.log(res);
        })
        .catch((err) => {
          this.success = false;
          this.loading = false;
          console.log("Enter XXXXXXXXXXXXX",this.success);
          this.course = err.response.data;
        });
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.verify();
    });
  },
};
</script>
<style>
.aboard-payment {
  position: relative;
  width: 100%;
  text-align: center;
  margin: 15px;
}
</style>
